import {
  IconClick,
  IconMessages,
  IconMobileSupport,
  IconSchedule,
  IconStarItinerary,
  IconPreferences
} from '@components/shared/Icons';

export const PROCESS_OPTIONS = [
  {
    h3: 'Make a request',
    bulletpoints: [
      {
        text: '<b>Click ‘Craft your trip’ and fill out the questionnaire</b> to be matched with a local expert.',
        desktopText:
          '<b>Click ‘Craft your trip’ below and fill out the questionnaire</b> to be matched with a local expert.',
        icon: <IconClick />
      },
      {
        text: '<b>Schedule a discovery call or send a message</b> to your expert to discuss the destination and any ideas or hopes you have for the trip. This call is free, with no obligation to continue.',
        icon: <IconMessages />
      }
    ]
  },
  {
    h3: 'Craft your trip',
    bulletpoints: [
      {
        text: '<b>Your expert will craft an itinerary for you,</b> with your exact travel dates, pricing, and availability. This personalized trip proposal costs $100 and will go towards your trip upon booking.',
        icon: <IconStarItinerary />
      },
      {
        text: '<b>Discuss options and alternatives</b> until your trip is tweaked to perfection.',
        icon: <IconPreferences />
      }
    ]
  },
  {
    h3: 'Booking & take-off',
    bulletpoints: [
      {
        text: '<b>Confirm your trip by paying a deposit.</b> Then your expert will lock in all your reservations.',
        icon: <IconSchedule />
      },
      {
        text: 'Your expert will be there for you throughout, offering <b>24/7 support and concierge service while traveling.</b> Whatever you need, your expert will take care of it.',
        icon: <IconMobileSupport />
      }
    ]
  }
];

export const FAQ = [
  {
    h3: 'What does an Elsewhere trip include?',
    p: [
      'When you travel with Elsewhere, we take care of everything (i.e. we are a full service company). This means, a trip with us includes personalized trip planning, booking and reservations, operations and logistics, 24/7 on-the-ground support, and on-trip concierge service.'
    ]
  },
  {
    h3: 'Are international flights included?',
    p: [
      'No, we specialize in on-the-ground services, so we stick to what we do best. From the moment your plane lands at your destination to the moment you return to the airport for your international flight home, we promise you’ll be in good hands.'
    ]
  },
  {
    h3: 'What is a local expert?',
    p: [
      'Our local experts are trip planners, not on-the-ground guides. They will pour their heart and soul into tailoring the perfect itinerary for you, and act as your on-the-ground support and concierge service while traveling.',
      'Our experts hold some of the highest accolades in the biz. We work exclusively with award-winning and certified local partners, true destination obsessives who have their finger on the pulse of what’s new, what’s hidden, and the things you should avoid. They’re pioneers in sustainable and community tourism and revolutionaries constantly on the quest to uncover new experiences and new places. But best of all, our experts are true locals, they’ve vetted and tested so you don’t have to, and have the personal connections to make anything happen.'
    ]
  }
];
